import Vue from 'vue';
import VueRouter from 'vue-router';
import {beforeRouteChangeCheck} from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes: [
        {
            path: '/statistics',
            name: 'statistics',
            component: () => import('@/views/Statistics.vue'),
            meta: {
                pageTitle: 'Statistics',
                breadcrumb: [
                    {
                        text: 'Statistics',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                pageTitle: 'Home',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/photos',
            name: 'photos',
            component: () => import('@/views/Photos.vue'),
            meta: {
                pageTitle: 'Photos',
                breadcrumb: [
                    {
                        text: 'Photos',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/deleteData',
            name: 'deleteData',
            component: () => import('@/views/settings/deleteData'),
            meta: {
                pageTitle: 'Delete Data ',
                breadcrumb: [
                    {
                        text: 'Delete Data',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/dailyUserReport',
            name: 'dailyUserReport',
            component: () => import('@/views/settings/DailyUserReport'),
            meta: {
                pageTitle: 'Daily User Report ',
                breadcrumb: [
                    {
                        text: 'Daily User Report',
                        active: true
                    }
                ]
            }
        },


        /* TABLES START */
        {
            path: '/tables/boq',
            name: 'table-boq',
            component: () => import('@/views/tables/Boq.vue'),
            meta: {
                pageTitle: 'BOQ Table',
                breadcrumb: [
                    {
                        text: 'BOQ Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/sub-boq',
            name: 'table-sub-boq',
            component: () => import('@/views/tables/SubBoq.vue'),
            meta: {
                pageTitle: 'Sub BOQ Table',
                breadcrumb: [
                    {
                        text: 'Sub BOQ Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/wbs-sub-boq',
            name: 'table-wbs-sub-boq',
            component: () => import('@/views/tables/WbsSubBoq.vue'),
            meta: {
                pageTitle: 'WBS Sub BOQ Table',
                breadcrumb: [
                    {
                        text: 'WBS Sub BOQ Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/wbs',
            name: 'table-wbs',
            component: () => import('@/views/tables/Wbs.vue'),
            meta: {
                pageTitle: 'WBS Table',
                breadcrumb: [
                    {
                        text: 'WBS Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/activity',
            name: 'table-activity',
            component: () => import('@/views/tables/Activity.vue'),
            meta: {
                pageTitle: 'Activity Table',
                breadcrumb: [
                    {
                        text: 'Activity Table',
                        active: true
                    }

                ]
            }
        },
        {
            path: '/tables/material',
            name: 'table-material',
            component: () => import('@/views/tables/Material.vue'),
            meta: {
                pageTitle: 'Material Table',
                breadcrumb: [
                    {
                        text: 'Material Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/unit',
            name: 'table-unit',
            component: () => import('@/views/tables/Unit.vue'),
            meta: {
                pageTitle: 'Unit Table',
                breadcrumb: [
                    {
                        text: 'Unit Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/work',
            name: 'table-work',
            component: () => import('@/views/tables/Work.vue'),
            meta: {
                pageTitle: 'Work Table',
                breadcrumb: [
                    {
                        text: 'Work Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/sub-contractor',
            name: 'table-sub-contractor',
            component: () => import('@/views/tables/SubContractor.vue'),
            meta: {
                pageTitle: 'Sub Contractor Table',
                breadcrumb: [
                    {
                        text: 'Sub Contractor Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/sub-contractor-user',
            name: 'table-sub-contractor-user',
            component: () => import('@/views/tables/SubContractorUser.vue'),
            meta: {
                pageTitle: 'Sub Contractor User Table',
                breadcrumb: [
                    {
                        text: 'Sub Contractor User Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/user',
            name: 'table-user',
            component: () => import('@/views/tables/User.vue'),
            meta: {
                pageTitle: 'User Table',
                breadcrumb: [
                    {
                        text: 'User Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/generalProblem',
            name: 'table-general-problem',
            component: () => import('@/views/tables/GeneralProblem.vue'),
            meta: {
                pageTitle: 'Problems',
                breadcrumb: [
                    {
                        text: 'Problems',
                        active: true
                    }
                ]
            }
        },

        {
            path: '/tables/meterials',
            name: 'table-meterials',
            component: () => import('@/views/tables/Materials.vue'),
            meta: {
                pageTitle: 'Materials',
                breadcrumb: [
                    {
                        text: 'Materials',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/reports/pivot',
            name: 'report-pivot',
            component: () => import('@/views/reports/Pivot.vue'),
            meta: {
                pageTitle: 'Pivot Report',
                breadcrumb: [
                    {
                        text: 'Pivot Report',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/reports/pivotProduction',
            name: 'report-pivot-try',
            component: () => import('@/views/reports/PivotTry'),
            meta: {
                pageTitle: 'Pivot Production',
                breadcrumb: [
                    {
                        text: 'Pivot Production',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/reports/pivotPersonel',
            name: 'report-pivot-personel',
            component: () => import('@/views/reports/PivotPersonnel'),
            meta: {
                pageTitle: 'Pivot Personnel',
                breadcrumb: [
                    {
                        text: 'Pivot Personnel',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/reports/pivotMachine',
            name: 'report-pivot-machine',
            component: () => import('@/views/reports/PivotMachine'),
            meta: {
                pageTitle: 'Pivot Machine',
                breadcrumb: [
                    {
                        text: 'Pivot Machine',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/reports/pivotMaterial',
            name: 'report-pivot-material',
            component: () => import('@/views/reports/PivotMaterial'),
            meta: {
                pageTitle: 'Pivot Material',
                breadcrumb: [
                    {
                        text: 'Pivot Material',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/reports/pivot2',
            name: 'report-pivot2',
            component: () => import('@/views/reports/Pivot2.vue'),
            meta: {
                pageTitle: 'Problem Report',
                breadcrumb: [
                    {
                        text: 'Problem Report',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/reports/pivot3',
            name: 'report-pivot3',
            component: () => import('@/views/reports/Pivot3.vue'),
            meta: {
                pageTitle: 'Revenue Report',
                breadcrumb: [
                    {
                        text: 'Revenue Report',
                        active: true
                    }
                ]
            }
        },
        /* TABLES START */
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full'
            }
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full'
            }
        },
        {
            path: '*',
            redirect: 'error-404'
        },
        {
            path: '/tables/organization-chart',
            name: 'table-organization-chart',
            component: () => import('@/views/tables/OrganizationChart.vue'),
            meta: {
                pageTitle: 'Organization Chart Table',
                breadcrumb: [
                    {
                        text: 'Organization Chart Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/workApprovalStatus',
            name: 'table-work-approval-status',
            component: () => import('@/views/tables/WorkApprovalStatus.vue'),
            meta: {
                pageTitle: 'Work Approval',
                breadcrumb: [
                    {
                        text: 'Work Approval',
                        active: true
                    }
                ]
            }
        }, {
            path: '/tables/note',
            name: 'table-note',
            component: () => import('@/views/tables/Note.vue'),
            meta: {
                pageTitle: 'Report Notes',
                breadcrumb: [
                    {
                        text: 'Report Notes',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/role',
            name: 'table-role',
            component: () => import('@/views/tables/Role.vue'),
            meta: {
                pageTitle: 'Role Table',
                breadcrumb: [
                    {
                        text: 'Role Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/role_pages',
            name: 'table-role-pages',
            component: () => import('@/views/tables/RolePages.vue'),
            meta: {
                pageTitle: 'Role - Pages Table',
                breadcrumb: [
                    {
                        text: 'Role - Pages Table',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/tables/hierarchy',
            name: 'table-hierarchy',
            component: () => import('@/views/tables/Hierarchy.vue'),
            meta: {
                pageTitle: 'Hierarchy',
                breadcrumb: [
                    {
                        text: 'Hierarchy',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/settings/companyPhoto',
            name: 'setting-companyPhoto',
            component: () => import('@/views/settings/CompanyPhoto'),
            meta: {
                pageTitle: 'Company Setting',
                breadcrumb: [
                    {
                        text: 'Company Setting',
                        active: true
                    }
                ]
            }
        },
        {
            path: '/homeWidgets/EcommerceOrderChart',
            name: 'setting-companyPhoto',
            component: () => import('@/views/homeWidgets/EcommerceOrderChart'),
        },

    ]
});

router.beforeEach((to, _from, next) => {
    const localStorageCheck =
        localStorage.token && localStorage.dbLanguage && localStorage.siteId;
    console.log(localStorageCheck)
    console.log(to)
    console.log("--*-*--")
    if (to.name === 'login' && localStorageCheck) {
        router.push({path: '/', query: {to: to.path}});
    }

    if (to.name !== 'login' && !localStorageCheck) {
        router.push({path: '/login', query: {to: to.path}});
    }
    if (freeRoutes.find(f => f === to.name)) {
        return next();
    }
    let canMove = beforeRouteChangeCheck(to.name)
    if (canMove || freeRoutes.find(f => f === to.name)) {
        return next();
    } else {
        router.push({path: '/error-404'})

    }

});

const freeRoutes = [
    'error-404',
    'login'
]

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;
