// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
import {blobToBase64ForLogo} from "@/auth/utils";

export const $themeColors = {};

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {};

// APP CONFIG
export const $themeConfig = {
    primary: '#fff',
    app: {
        appName: getCompanyName(),
        // eslint-disable-next-line global-require
        appLogoImage: localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).companyLogo ? blobToBase64ForLogo2() : require('@/assets/images/logo/logo.png'),
        refId: (new Date()).getTime()
    },
    layout: {
        isRTL: false,
        skin: 'light', // light, dark, bordered, semi-dark
        routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
        type: 'vertical', // vertical, horizontal
        contentWidth: 'full', // full, boxed
        menu: {
            hidden: false,
            isCollapsed: false
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: 'floating', // static , sticky , floating, hidden
            backgroundColor: '' // BS color options [primary, success, etc]
        },
        footer: {
            type: 'static' // static, sticky, hidden
        },
        customizer: true,
        enableScrollToTop: true
    }
};

export function blobToBase64ForLogo2() {
    let q = JSON.parse(localStorage.getItem('userInfo'))
    let blob = q.companyLogo
    let logo = btoa(
        blob.reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    let result = 'data:image/png;base64,' + logo
    return result
}
export function getCompanyName() {
    let name = "Famametric"
    if(localStorage.getItem('userInfo')){
        let q = JSON.parse(localStorage.getItem('userInfo'))
        console.log(q.companyName)
        name = q.companyName
    }
    return name
}
