import useJwt from '@/auth/jwt/useJwt';
import crypto from "crypto";
// const { IV, SECRET } = process.env;
const algorithm = 'aes-256-cbc';
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
    return (
        localStorage.getItem('userData') &&
        localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
    );
};
export const blobToBase64ForLogo = (blob) => {
    console.log(blob)
    return btoa(
        blob.reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
}
export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});

export function readOnly() {
    let sites = localStorage.getItem('sites')
    console.log(sites)
    if(sites){
        return  JSON.parse(sites).length <= 1
    } else {
    return      true
    }


}

export function blobToBase64ForLogo2() {
    let q = JSON.parse(localStorage.getItem('userInfo'))
    let result = blobToBase64ForLogo(q.companyLogo)
    // console.log(result)
    return result
}

export const getUserData = () => JSON.parse(localStorage.getItem('userInfo'));

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
    if (userRole === 'admin') return '/';
    if (userRole === 'client') return {name: 'access-control'};
    return {name: 'auth-login'};
};
export const decryptJwt = (hash) => {
    const SECRET = '9deedf4980d864b703c4c922363e26dec11d9639ebf3035fb6b5fdb07d9d08ed'
    const IV = '669d2c1f1204c589db4cd92f0bf47bc9'
    try {
        const decipher = crypto.createDecipheriv(algorithm, Buffer.from(SECRET, 'hex'), Buffer.from(IV, 'hex'));
        const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash, 'hex')), decipher.final()]);
        let result = decrpyted.toString()
        let modules = parseJwt(result)
        return modules;
    } catch (error) {
        console.log(`[DECRYPT JWT ERROR]: ${error}`);
        return '';
    }
};
export const beforeRouteChangeCheck = (routeName) => {
    const hash = localStorage.getItem('token')
    let token = []
    token = decryptJwt(hash)
    console.log(token)
    console.log("----token*-----")
    let result = token.find(t => t.route_name === routeName)
    return result !== undefined
}
export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let result = JSON.parse(jsonPayload)
    return result.modules;
};

